import logo from 'mastodon/../images/logo.svg';

export const WordmarkLogo: React.FC = () => (
  <div style={{ width: '100%', height: '120px' }}>
    <svg viewBox='0 0 391 60' className='logo logo--wordmark' role='img' style={{height:'100%',width:'100%'}}>
    <use href="#logo-symbol-wordmark"></use>
      <g transform='translate(0, -20) scale(0.8)'>
        <g id='Layer-1'>
          <path
            d='M145.446 14.8258C144.895 14.8328 75.4349 15.6938 74.8836 15.7008C73.1341 15.723 71.7996 17.2848 72.0711 19.0133L84.6961 99.2945C84.7385 99.5649 84.798 99.8283 84.9148 100.076L86.7898 104.076L96.0398 79.4508L108.384 79.4508L122.134 116.076L110.29 116.076L108.509 110.045L95.6023 110.045L93.8523 116.076L85.9148 116.076L82.3523 126.013C82.1496 126.579 82.1556 127.185 82.3211 127.763L84.9773 137.138L83.0398 148.482C82.9582 148.956 82.9779 149.464 83.1336 149.92L88.1023 164.42C88.4726 165.503 89.4607 166.266 90.6023 166.357L167.884 172.513L169.165 176.951C169.571 178.34 170.954 179.212 172.384 178.982L176.477 178.326C177.845 178.106 178.882 176.96 178.915 175.576L179.165 164.17L179.79 163.982L191.571 166.701C193.355 167.111 195.077 165.75 195.102 163.92C195.103 163.851 195.109 163.68 195.134 163.357C195.178 162.778 195.235 162.056 195.352 161.232C195.696 158.827 196.33 155.965 197.259 152.638C199.943 143.023 204.687 131.759 212.071 118.92C212.814 117.627 212.452 115.958 211.227 115.107L207.759 112.701C207.319 112.395 206.804 112.202 206.29 112.17L202.571 100.232C202.373 99.596 201.972 99.0657 201.415 98.7008L197.477 96.107L182.102 72.8258L182.227 69.482C182.255 68.6426 181.91 67.83 181.29 67.2633L163.384 50.857L154.946 33.3883C154.677 32.8307 154.245 32.362 153.696 32.0758L142.665 26.3258L147.79 19.3883C149.202 17.4785 147.821 14.7956 145.446 14.8258ZM123.946 79.4508L158.321 79.4508L158.321 88.5133L146.79 88.5133L146.79 116.076L135.477 116.076L135.477 88.5133L123.946 88.5133L123.946 79.4508ZM167.727 79.4508L179.04 79.4508L179.04 107.076L197.696 107.076L198.583 116.076L167.727 116.076L167.727 79.4508ZM102.071 88.9508L98.0711 102.107L106.134 102.107L102.071 88.9508Z'
            fill='#ffc304'
            fill-rule='nonzero'
            opacity='1'
            stroke='none'
          />
          <path
            d='M181.55 67.7303L181.188 103.799L205.12 103.826L181.55 67.7303Z'
            fill='#ffffff'
            fill-rule='nonzero'
            opacity='1'
            stroke='none'
          />
          <path
            d='M179.766 46.4657L366.771 46.4657L366.771 83.384L366.771 105.862L179.766 105.862L179.766 73.1942L179.766 46.4657Z'
            fill='#ffffff'
            fill-rule='nonzero'
            opacity='1'
            stroke='#000000'
            stroke-linecap='butt'
            stroke-linejoin='round'
            stroke-width='2.61915'
          />
          <g opacity='1'>
            <path
              d='M193.551 84.159L198.166 84.159L198.166 88.9724L193.551 88.9724L193.551 84.159Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M216.506 89.4246C214.969 89.4246 213.454 89.0629 211.961 88.3394C210.469 87.6158 209.241 86.3798 208.276 84.6313L211.713 82.3703C212.557 83.4858 213.356 84.2922 214.109 84.7896C214.863 85.287 215.843 85.5357 217.049 85.5357C218.013 85.5357 218.759 85.287 219.287 84.7896C219.815 84.2922 220.078 83.6817 220.078 82.9582C220.078 82.4759 219.95 82.0538 219.694 81.6921C219.438 81.3303 218.993 80.9911 218.36 80.6746C217.727 80.3581 216.823 80.0491 215.647 79.7476C213.476 79.1748 211.803 78.4212 210.627 77.4866C209.452 76.5521 208.864 75.0749 208.864 73.0551C208.864 71.8492 209.165 70.7489 209.768 69.754C210.371 68.7592 211.245 67.9603 212.391 67.3574C213.537 66.7544 214.893 66.453 216.461 66.453C218.33 66.453 219.928 66.8373 221.254 67.6061C222.581 68.3748 223.621 69.2868 224.374 70.3419L220.892 72.9194C220.47 72.3768 219.852 71.8417 219.038 71.3141C218.224 70.7865 217.199 70.5228 215.963 70.5228C215.21 70.5228 214.516 70.7338 213.883 71.1558C213.25 71.5779 212.934 72.1657 212.934 72.9194C212.934 73.6731 213.228 74.2835 213.815 74.7508C214.403 75.2181 215.481 75.6326 217.049 75.9944C219.702 76.6274 221.586 77.4489 222.701 78.4588C223.817 79.4687 224.374 80.8027 224.374 82.4608C224.374 83.9078 224.005 85.1514 223.266 86.1914C222.528 87.2315 221.563 88.0304 220.372 88.5881C219.182 89.1458 217.893 89.4246 216.506 89.4246Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M245.169 89.4246C242.938 89.4246 240.971 88.9498 239.268 88.0002C237.564 87.0506 236.246 85.7166 235.311 83.9983C234.376 82.2799 233.909 80.2752 233.909 77.984C233.909 75.6627 234.376 73.6429 235.311 71.9246C236.246 70.2062 237.564 68.8647 239.268 67.9C240.971 66.9353 242.938 66.453 245.169 66.453C247.43 66.453 249.405 66.9353 251.093 67.9C252.781 68.8647 254.1 70.2062 255.049 71.9246C255.999 73.6429 256.474 75.6627 256.474 77.984C256.474 80.2752 255.999 82.2799 255.049 83.9983C254.1 85.7166 252.781 87.0506 251.093 88.0002C249.405 88.9498 247.43 89.4246 245.169 89.4246ZM245.169 85.0835C247.189 85.0835 248.809 84.4279 250.03 83.1165C251.251 81.8051 251.861 80.0943 251.861 77.984C251.861 75.8436 251.251 74.1102 250.03 72.7837C248.809 71.4573 247.189 70.7941 245.169 70.7941C243.179 70.7941 241.574 71.4498 240.353 72.7611C239.132 74.0725 238.522 75.7984 238.522 77.9388C238.522 80.0792 239.132 81.8051 240.353 83.1165C241.574 84.4279 243.179 85.0835 245.169 85.0835Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M276.228 89.4246C273.967 89.4246 272.008 88.9348 270.35 87.955C268.692 86.9752 267.411 85.6262 266.506 83.9078C265.602 82.1895 265.15 80.1998 265.15 77.9388C265.15 75.6778 265.602 73.6881 266.506 71.9698C267.411 70.2514 268.692 68.9024 270.35 67.9226C272.008 66.9429 273.967 66.453 276.228 66.453C278.369 66.453 280.313 67.0333 282.062 68.1939C283.81 69.3546 285.076 70.8996 285.86 72.829L281.564 74.6378C281.082 73.3716 280.359 72.4145 279.394 71.7663C278.429 71.1182 277.329 70.7941 276.093 70.7941C274.284 70.7941 272.777 71.4196 271.571 72.6707C270.365 73.9218 269.762 75.6778 269.762 77.9388C269.762 80.1998 270.365 81.9558 271.571 83.2069C272.777 84.458 274.284 85.0835 276.093 85.0835C277.329 85.0835 278.429 84.7595 279.394 84.1113C280.359 83.4632 281.082 82.506 281.564 81.2399L285.86 83.0486C285.046 84.978 283.773 86.523 282.039 87.6837C280.306 88.8443 278.369 89.4246 276.228 89.4246Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M300.595 88.9724L296.074 88.9724L296.074 66.9052L300.595 66.9052L300.595 88.9724ZM295.395 60.1222C295.395 59.248 295.659 58.532 296.187 57.9743C296.714 57.4165 297.43 57.1377 298.334 57.1377C299.209 57.1377 299.917 57.4165 300.46 57.9743C301.002 58.532 301.274 59.248 301.274 60.1222C301.274 60.9663 301.002 61.6672 300.46 62.2249C299.917 62.7826 299.209 63.0615 298.334 63.0615C297.43 63.0615 296.714 62.7826 296.187 62.2249C295.659 61.6672 295.395 60.9663 295.395 60.1222Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M319.581 89.6055C317.863 89.6055 316.386 89.3041 315.15 88.7011C313.914 88.0982 312.972 87.2315 312.324 86.101C311.675 84.9705 311.351 83.6214 311.351 82.0538C311.351 80.6068 311.683 79.318 312.346 78.1875C313.009 77.057 313.959 76.1602 315.195 75.4969C316.431 74.8337 317.893 74.5021 319.581 74.5021C321.692 74.5021 323.387 74.9769 324.669 75.9265C325.95 76.8761 326.847 78.18 327.359 79.838L324.827 79.838L324.827 74.1856C324.827 73.251 324.51 72.3918 323.877 71.608C323.244 70.8242 322.129 70.4323 320.531 70.4323C319.687 70.4323 318.76 70.5303 317.75 70.7262C316.74 70.9222 315.692 71.2613 314.607 71.7437L313.251 68.2618C314.487 67.6588 315.768 67.2066 317.094 66.9052C318.421 66.6037 319.717 66.453 320.983 66.453C322.882 66.453 324.45 66.7997 325.686 67.493C326.922 68.1864 327.842 69.1134 328.444 70.274C329.047 71.4347 329.349 72.7385 329.349 74.1856L329.349 88.9724L326.093 88.9724L324.963 84.9479L327.359 84.2696C326.817 86.0482 325.852 87.3822 324.465 88.2715C323.078 89.1609 321.45 89.6055 319.581 89.6055ZM320.395 85.581C321.722 85.581 322.792 85.272 323.606 84.654C324.42 84.0359 324.827 83.1692 324.827 82.0538C324.827 80.9082 324.42 80.034 323.606 79.4311C322.792 78.8281 321.722 78.5267 320.395 78.5267C319.069 78.5267 317.999 78.8281 317.185 79.4311C316.371 80.034 315.964 80.9082 315.964 82.0538C315.964 83.1692 316.371 84.0359 317.185 84.654C317.999 85.272 319.069 85.581 320.395 85.581Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M344.762 89.1985C343.979 89.1985 343.285 89.0101 342.682 88.6333C342.079 88.2565 341.604 87.7063 341.258 86.9828C340.911 86.2593 340.738 85.3699 340.738 84.3148L340.738 58.1778L345.169 58.1778L345.169 82.7321C345.169 83.6667 345.403 84.2997 345.87 84.6313C346.337 84.963 346.873 85.1288 347.476 85.1288L347.476 88.7463C347.084 88.8971 346.662 89.0101 346.209 89.0855C345.757 89.1609 345.275 89.1985 344.762 89.1985Z'
              fill='#131313'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
          </g>
          <path
            d='M198.096 90.5534C198 90.5977 197.923 90.653 197.864 90.7194C197.805 90.7858 197.76 90.8559 197.731 90.9296C197.701 91.0034 197.687 94.987 197.687 102.88C197.687 110.774 197.694 114.742 197.709 114.787C197.724 114.831 197.768 114.901 197.842 114.997C197.915 115.093 198 115.163 198.096 115.207C198.192 115.251 198.292 115.274 198.395 115.274C198.498 115.274 198.598 115.251 198.694 115.207C198.737 115.187 198.934 115.017 199.286 114.695C199.722 114.296 200.395 113.667 201.305 112.806C202.95 111.249 203.817 110.438 203.905 110.372C203.95 110.338 203.994 110.305 204.038 110.272C204.732 111.88 205.425 113.488 206.119 115.097C207.505 118.313 208.217 119.943 208.254 119.987C208.291 120.032 208.354 120.08 208.442 120.131C208.531 120.183 208.638 120.212 208.763 120.22C208.889 120.227 208.977 120.223 209.029 120.209C209.08 120.194 209.645 119.939 210.722 119.445C211.799 118.951 212.382 118.645 212.47 118.527C212.559 118.409 212.607 118.28 212.614 118.139C212.621 117.999 211.895 116.34 210.434 113.16C209.704 111.57 208.973 109.981 208.243 108.391C209.457 108.288 210.67 108.184 211.884 108.081C214.311 107.874 215.579 107.745 215.69 107.694C215.801 107.642 215.882 107.587 215.934 107.528C215.985 107.469 216.026 107.402 216.055 107.329C216.085 107.255 216.096 107.152 216.088 107.019C216.081 106.886 216.059 106.786 216.022 106.72C215.985 106.654 215.911 106.565 215.801 106.454C215.69 106.344 212.868 103.707 207.336 98.5426C201.803 93.3788 198.978 90.7526 198.86 90.6641C198.742 90.5755 198.609 90.5239 198.461 90.5092C198.314 90.4944 198.192 90.5092 198.096 90.5534Z'
            fill='#000000'
            fill-rule='nonzero'
            opacity='1'
            stroke='#ffffff'
            stroke-linecap='butt'
            stroke-linejoin='round'
            stroke-width='1.61915'
          />
          <path
            d='M97.9577 63.1962L98.8212 57.0865L94.2777 52.9114L100.355 51.8446L102.922 46.2333L105.815 51.6838L111.944 52.3909L107.655 56.8262L108.876 62.8746L103.332 60.1652L97.9577 63.1962Z'
            fill='#000000'
            fill-rule='nonzero'
            opacity='1'
            stroke='none'
          />
          <g opacity='1'>
            <path
              d='M26.8184 116.191C24.6356 116.191 22.9047 115.551 21.6258 114.272C20.3468 112.993 19.7073 111.007 19.7073 108.312L19.7073 91.0714L19.7073 90.7644L20.6793 85.1369L24.8232 85.1369L24.8232 108.414C24.8232 109.369 25.0705 110.086 25.565 110.563C26.0596 111.041 26.682 111.279 27.4324 111.279C28.0122 111.279 28.5238 111.245 28.9671 111.177C29.4105 111.109 29.8028 111.024 30.1438 110.921L30.1438 115.679C29.7004 115.816 29.2144 115.935 28.6858 116.037C28.1571 116.14 27.5347 116.191 26.8184 116.191ZM15.8703 95.4711L15.8703 90.7644L30.1438 90.7644L30.1438 95.4711L15.8703 95.4711Z'
              fill='#ffc804'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M35.976 115.73L35.976 80.8906L41.092 80.8906L41.092 115.73L35.976 115.73ZM52.7052 115.73L52.7052 99.7685C52.7052 97.995 52.3044 96.8012 51.5029 96.1873C50.7014 95.5734 49.755 95.2665 48.6636 95.2665C47.5722 95.2665 46.4637 95.4881 45.3382 95.9315C44.2127 96.3749 43.2065 96.9718 42.3198 97.7221C41.433 98.4725 40.7509 99.3081 40.2734 100.229L39.6595 95.3176C40.6145 94.2262 41.6547 93.3053 42.7802 92.555C43.9057 91.8047 45.0994 91.2334 46.3614 90.8412C47.6233 90.4489 48.9194 90.2528 50.2495 90.2528C52.023 90.2528 53.4214 90.5513 54.4446 91.1481C55.4678 91.745 56.2181 92.5465 56.6956 93.5526C57.1731 94.5587 57.48 95.6928 57.6165 96.9547C57.7529 98.2167 57.8211 99.5127 57.8211 100.843L57.8211 115.73L52.7052 115.73Z'
              fill='#ffc804'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
            <path
              d='M74.6014 116.242C72.1799 116.242 70.0482 115.705 68.2065 114.63C66.3648 113.556 64.9323 112.047 63.9091 110.103C62.8859 108.159 62.3743 105.891 62.3743 103.299C62.3743 100.672 62.8859 98.3872 63.9091 96.4431C64.9323 94.4991 66.3648 92.9813 68.2065 91.8899C70.0482 90.7985 72.1799 90.2528 74.6014 90.2528C77.0571 90.2528 79.2058 90.7985 81.0475 91.8899C82.8893 92.9813 84.3217 94.4991 85.3449 96.4431C86.3681 98.3872 86.8797 100.672 86.8797 103.299C86.8797 103.537 86.8712 103.785 86.8541 104.04C86.8371 104.296 86.8115 104.526 86.7774 104.731L67.2345 104.731L67.2345 100.331L83.0939 100.331L81.6614 103.299C81.6614 100.706 81.0816 98.5748 79.922 96.9036C78.7624 95.2324 76.9889 94.3967 74.6014 94.3967C72.4868 94.3967 70.7986 95.0703 69.5366 96.4175C68.2747 97.7647 67.6437 99.5297 67.6437 101.713L67.6437 104.373C67.6437 106.658 68.2577 108.474 69.4855 109.821C70.7133 111.169 72.4186 111.842 74.6014 111.842C76.6137 111.842 78.1655 111.416 79.2569 110.563C80.3484 109.71 81.3033 108.653 82.1219 107.391L85.9588 109.796C84.7992 111.944 83.3071 113.556 81.4824 114.63C79.6577 115.705 77.364 116.242 74.6014 116.242Z'
              fill='#ffc804'
              fill-rule='nonzero'
              opacity='1'
              stroke='none'
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const SymbolLogo: React.FC = () => (
  <img src={logo} alt='Mastodon' className='logo logo--icon' />
);
